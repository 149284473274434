<template>
  <div class="index">
    <router-view />
  </div>
  <!-- <van-tabbar v-model="active" @change="currentRouter">
    <van-tabbar-item name="/user" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item icon="newspaper-o">项目履历</van-tabbar-item>
    <van-tabbar-item icon="label-o">行为记录</van-tabbar-item>
    <van-tabbar-item icon="bullhorn-o">安全教育</van-tabbar-item>
    <van-tabbar-item icon="medal-o">证书信息</van-tabbar-item>
  </van-tabbar> -->
</template>

<script >


export default {
		name: "UserIndex",
		data() {
			return {
				idNo: '' || window.sessionStorage.getItem('id'),
			}
		},
		created() {
			console.log(this.$route.query.id);
			if (this.$route.query.id) {
			  window.sessionStorage.setItem('id', this.$route.query.id)
			  this.idNo = this.$route.query.id
			}
			console.log( this.idNo );
		}
	}
</script>
<style lang="scss" scoped>
.index {
  height: calc(100% - 1.33333rem);
  overflow: hidden;
}
</style>
